.horizontalContainer {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
}

.horizontalContainer::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.horizontalContainer::-webkit-scrollbar-thumb {
  background-color: #606060;
  border-radius: 8px;
  background-clip: padding-box;
  border: 4px solid #606060;
}

.horizontalContainer::-webkit-scrollbar-corner {
  background: 'transparent';
}
.sub-container-1 {
  width: 80%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 10px;
  margin-right: 10px;
}

.sub-container-1::-webkit-scrollbar {
  background-color: 'transparent';
  width: 8px;
  height: 5px;
}

.sub-container-1::-webkit-scrollbar-thumb {
  background-color: #434343;
  border-radius: 8px;
  background-clip: padding-box;
  border: 4px solid #434343;
}

.sub-container-1::-webkit-scrollbar-corner {
  background: 'tranparent';
}
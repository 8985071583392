.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
}

.container::-webkit-scrollbar {
  background-color: 'transparent';
  width: 5px;
  height: 5px;
}

.container::-webkit-scrollbar-thumb {
  background-color: #606060;
  border-radius: 8px;
  background-clip: padding-box;
  border: 4px solid #606060;
}

.container::-webkit-scrollbar-corner {
  background: 'tranparent';
}
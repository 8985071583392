.pdfContainer {
  width: 100%;
  max-height: 100vh;
  padding: 72px 51px;
  overflow: auto;
  background-color: #262626;
}

.pdfContainer::-webkit-scrollbar {
  background-color: 'transparent';
  width: 10px;
  height: 144px;
}

.pdfContainer::-webkit-scrollbar-thumb {
  background-color: #606060;
  border-radius: 8px;
  background-clip: padding-box;
  border: 4px solid #606060;
}

.pdfContainer::-webkit-scrollbar-corner {
  background: 'tranparent';
}

.pdfWrapper {
  height: 100%;
}

.react-pdf__Page {
  margin-top: 65.5px;
  width: 100%;
}

[data-page-number="1"] {
  margin-top: 0;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
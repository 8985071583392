@import-normalize;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
}

input {
  outline: none;
  background-color: black;
}

@font-face {
  font-family: "Roboto Mono";
  src: url(../shared/assets/fonts/RobotoMono-Regular.ttf);
}

@font-face {
  font-family: "Roboto";
  src: url(../shared/assets/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: "RobotoMono-Medium";
  src: url(../shared/assets/fonts/RobotoMono-Medium.ttf);
}

@font-face {
  font-family: "RobotoMono-Regular";
  src: url(../shared/assets/fonts/RobotoMono-Regular.ttf);
}

@font-face {
  font-family: "RobotoMono-Thin";
  src: url(../shared/assets/fonts/RobotoMono-Thin.ttf);
}

@font-face {
  font-family: "RobotoMono-Light";
  src: url(../shared/assets/fonts/RobotoMono-Light.ttf);
}

@font-face {
  font-family: "RobotoMono-ExtraLight";
  src: url(../shared/assets/fonts/RobotoMono-ExtraLight.ttf);
}

@font-face {
  font-family: "RobotoMono-Bold";
  src: url(../shared/assets/fonts/RobotoMono-Bold.ttf);
}

@font-face {
  font-family: "RobotoMono-SemiBold";
  src: url(../shared/assets/fonts/RobotoMono-SemiBold.ttf);
}

@font-face {
  font-family: "Oxygen";
  src: url(../shared/assets/fonts/Oxygen-Regular.ttf);
}

@font-face {
  font-family: "Oxygen-Light";
  src: url(../shared/assets/fonts/Oxygen-Light.ttf);
}
